import { useBoolean } from "@chakra-ui/react";
import { blackboardApi, documentItemApi } from "apiClient/v2";
import { TypeOfEquipmentField } from "constants/documentTemplate";
import { CellProperty, LinkedDataField, SubItemKey } from "constants/enum";
import { S3_PATH } from "constants/s3";
import { BLACKBOARD_CONTAINER_ID } from "constants/styleProps";
import { MessageType } from "constants/websocket";
import dayjs from "dayjs";
import { useRoles } from "hooks/usePermission";
import {
  DocumentItemDTO,
  DocumentSubItemDTO,
} from "interfaces/dtos/documentItemDTO";
import { FamilyInstanceDTO } from "interfaces/dtos/familyInstance";
import { Blackboard, CommentManageExecute } from "interfaces/models/blackboard";
import { CellType } from "interfaces/models/component";
import { DocumentTemplate } from "interfaces/models/documentTemplate";
import { SizePosition } from "interfaces/models/rnd";
import { WSMessage } from "interfaces/models/websocket";
import { GetContentLog } from "models/dataLog";
import { getCommentManageExecuteDefault } from "pages/document/template-page/utils";
import { ReceivedDeviceType } from "pages/forge-viewer/context/AppIosConnection/useAppIOSConnection";
import { useForgeViewerContext } from "pages/forge-viewer/ForgeViewerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceSelectors } from "react-device-detect";
import { useDispatch } from "react-redux";
import {
  setDataBlackboard,
  setDataBlackboards,
  updateDocumentItem,
} from "redux/documentSlice";
import { updateElementInArray } from "utils/array";
import { sleep, uuid } from "utils/common";
import { snapShotBlackboard } from "utils/data-logs";
import { isLatestByUpdatedTime } from "utils/date";
import { dataURLtoFile, uploadFileToS3 } from "utils/file";
import { logDev } from "utils/logs";
import { checkDiffTwoObject } from "utils/object";
import { getAllCells } from "utils/tableCell";

interface Props {
  subItem?: DocumentSubItemDTO;
  documentTemplate?: DocumentTemplate;
  isLoadedBlackboard: boolean;
  dataBlackBoards: Blackboard[];
  dataBlackboardDefault?: Partial<Blackboard>;
  documentItemSelected?: DocumentItemDTO;
  isDisabled?: boolean;
  webSocketMessages: WSMessage[];
  familyInstance: FamilyInstanceDTO;

  insertItemLog?: (params: GetContentLog) => Promise<void>;
}

let timeoutChangeItemData: any;

const useBlackboard = (props: Props) => {
  const {
    subItem,
    documentTemplate,
    dataBlackBoards,
    isLoadedBlackboard,
    documentItemSelected,
    dataBlackboardDefault,
    isDisabled,
    webSocketMessages,
    familyInstance,
    insertItemLog,
  } = props;

  const { socket } = useForgeViewerContext();
  const [isSavingDataFromDevice, setIsSavingDataFromDevice] = useBoolean();
  const [isFocusBlackboard, setIsFocusBlackboard] = useBoolean();
  const [isOpenSelectBlackboardPosition, setIsOpenSelectBlackboardPosition] =
    useBoolean();
  const [dataBlackboard, setDataBlackboardState] = useState<
    Blackboard | undefined
  >();
  const [loadingGetBlackboardData, setLoadingGetBlackboardData] =
    useBoolean(true);
  const dispatch = useDispatch();

  const [{ isMobile }] = useDeviceSelectors(window.navigator.userAgent);
  const dataBlackBoardDefaultRef = useRef<Partial<Blackboard> | undefined>();

  const dataBlackBoardRef = useRef<Blackboard | undefined>(dataBlackboard);
  const dataBlackBoardsRef = useRef<Blackboard[]>(dataBlackBoards);
  const tempBlackboardChangedRef = useRef(dataBlackboard);
  const prevDataBlackboardRef = useRef<Blackboard | undefined>(dataBlackboard);

  tempBlackboardChangedRef.current = dataBlackboard;

  const { isTakasagoGroup } = useRoles();

  const dataBlackboardRedux = useMemo(
    () => dataBlackBoards.find((data) => data.id === dataBlackboard?.id),
    [dataBlackboard?.id, dataBlackBoards]
  );

  const blackboardTemplate = useMemo(
    () => documentTemplate?.blackboardTemplateDetail,
    [documentTemplate?.blackboardTemplateDetail]
  );

  const isShowBlackboard = useMemo(
    () => !!blackboardTemplate?.id && subItem?.isShowBlackboard !== false,
    [blackboardTemplate?.id, subItem?.isShowBlackboard]
  );

  const blackboardPosition = useMemo(() => {
    const pos = subItem?.blackboardImagePosition as SizePosition;
    if (!subItem?.images?.src) {
      return [0, 0, 0, 0, 0, 0];
    }

    return [
      pos?.x ?? 0,
      pos?.y ?? 0,
      pos?.width ?? 0,
      pos?.imgWidth ?? 0,
      pos?.height ?? 0,
      pos?.imgHeight ?? 0,
    ];
  }, [subItem?.blackboardImagePosition, subItem?.images?.src]);

  const cellComment = useMemo(() => {
    const cells = (blackboardTemplate?.components || [])
      ?.map((com) => getAllCells(com))
      .flat(1);

    return cells.find(
      (cell) =>
        cell.cellLinkedData?.field ===
        LinkedDataField.BLACKBOARD.COMMENT_MANAGE_EXECUTE
    );
  }, [blackboardTemplate]);

  const isAllowDeviceLinkage = useMemo(() => {
    const cells = cellComment?.cellLinkedData?.options?.blackboard?.cells || [];

    return !!cells?.find(
      (cell) => cell.cellProperty === CellProperty.TYPE_OF_EQUIPMENT
    );
  }, [cellComment]);

  dataBlackBoardDefaultRef.current = dataBlackboardDefault;
  dataBlackBoardRef.current = dataBlackboard;

  useEffect(() => {
    if (dataBlackboardRedux?.id === dataBlackboard?.id) {
      setDataBlackboardState(dataBlackboardRedux);
    }
  }, [dataBlackboardRedux, dataBlackboard?.id]);

  useEffect(() => {
    if (!webSocketMessages.length) {
      return;
    }

    webSocketMessages.forEach((e) => {
      const { type, data } = e;

      if (
        type === MessageType.UPDATE_BLACKBOARD &&
        data.id === dataBlackBoardRef.current?.id
      ) {
        setDataBlackboardState((prev) => {
          if (!isLatestByUpdatedTime(prev, data)) return prev;

          return { ...prev, ...data };
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketMessages]);

  // init data for blackboard
  useEffect(() => {
    (async () => {
      if (!subItem?.id || !documentItemSelected?.id || !isLoadedBlackboard) {
        setLoadingGetBlackboardData.off();

        return;
      }

      const now = new Date();
      const blackboardId = subItem?.blackboardId;
      let dataBlackBoard: Blackboard | undefined;
      if (blackboardId) {
        dataBlackBoard = dataBlackBoardsRef.current?.find(
          (item) => item.id === blackboardId
        );
        if (!dataBlackBoard) {
          const { data } = await blackboardApi.getBlackboardList({
            documentCategoryId: documentItemSelected.documentCategoryId,
          });

          dispatch(setDataBlackboards(data));
          dataBlackBoard = data.find((item) => item.id === blackboardId);
        }
      } else if (dataBlackBoardDefaultRef.current && isTakasagoGroup) {
        const requestId = uuid();

        const { data } = await blackboardApi.createBlackboard({
          ...dataBlackBoardDefaultRef.current,
          subItemId: subItem.id,
          requestId,
        });

        dataBlackBoard = data;

        if (dataBlackBoard?.id) {
          const itemItem = structuredClone(documentItemSelected);
          updateElementInArray({
            array: itemItem?.subItems || [],
            keyIndex: SubItemKey.ID,
            element: {
              ...subItem,
              updatedAt: now,
              blackboardId: dataBlackBoard.id,
            } as DocumentSubItemDTO,
          });
          socket.updateDocItem(itemItem, {
            subItems: itemItem?.subItems,
            updatedAt: dataBlackBoard.updatedAt,
          });
          socket.updateBlackboard(
            itemItem.level!,
            dataBlackBoard.id,
            dataBlackBoard
          );
          dispatch(updateDocumentItem(itemItem));
          dispatch(setDataBlackboard(dataBlackBoard));
        }
      }

      setDataBlackboardState(dataBlackBoard);
      setLoadingGetBlackboardData.off();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subItem?.id,
    documentItemSelected?.id,
    isLoadedBlackboard,
    socket,
    isTakasagoGroup,
  ]);

  const handleClosePositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.off();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenPositionBlackboardModal = useCallback(() => {
    setIsOpenSelectBlackboardPosition.on();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeItemData = useCallback(
    (key: keyof DocumentSubItemDTO) => async (e: any) => {
      if (!documentItemSelected?.id || !subItem?.id || isDisabled) {
        return;
      }

      const documentItem = structuredClone(documentItemSelected);
      const now = new Date();

      if (key === "isShowBlackboard") {
        const requestId = uuid();
        const isShowBlackboard = e?.target?.checked || false;
        updateElementInArray({
          array: documentItem?.subItems || [],
          keyIndex: SubItemKey.ID,
          element: {
            ...subItem,
            isShowBlackboard,
            updatedAt: now,
          },
        });

        clearTimeout(timeoutChangeItemData);
        timeoutChangeItemData = setTimeout(async () => {
          const { data } = await documentItemApi.updateSubItem({
            id: subItem.id,
            itemId: subItem.itemId,
            isShowBlackboard,
            mapTitleKey: { isShowBlackboard: "isShowBlackboard" } as any,
            requestId,
            updatedAt: now,
          } as DocumentSubItemDTO);

          socket.updateSubItem(documentItem, subItem, {
            isShowBlackboard,
            updatedAt: data.updatedAt,
          });

          insertItemLog?.({
            field: "isShowBlackboard" as any,
            value: isShowBlackboard,
            requestId,
          });
        }, 300);
      }
      dispatch(updateDocumentItem(documentItem));
    },
    [documentItemSelected, subItem, isDisabled, insertItemLog, dispatch, socket]
  );

  const handleSaveBlackboardData = useCallback(async () => {
    const blackboardChanged = tempBlackboardChangedRef.current;
    setIsFocusBlackboard.off();
    if (
      !dataBlackboard?.id ||
      isDisabled ||
      !subItem?.id ||
      !blackboardChanged?.id
    ) {
      return;
    }

    const prevBlackboardChanged =
      prevDataBlackboardRef.current || dataBlackboard;

    const { dataChanges } = checkDiffTwoObject({
      object: blackboardChanged,
      base: prevBlackboardChanged,
    });

    if (!Object.keys(dataChanges).length) {
      return;
    }

    const bodyUpdate = Object.keys(dataChanges).reduce((acc: any, key) => {
      acc[key] = blackboardChanged?.[key as keyof typeof blackboardChanged];

      return acc;
    }, {});

    let newDataBlackboard: Blackboard = {
      ...dataBlackboard,
      ...blackboardChanged,
      ...bodyUpdate,
      subItemId: subItem.id,
    };

    // temp data's saved
    tempBlackboardChangedRef.current = newDataBlackboard;
    prevDataBlackboardRef.current = newDataBlackboard;

    const requestId = uuid();
    let thumbnail = "";
    const image = await snapShotBlackboard({
      elementId: BLACKBOARD_CONTAINER_ID,
      isMobile,
    });

    if (image) {
      const file = dataURLtoFile(image, "thumbnail.jpeg");
      thumbnail = await uploadFileToS3(file, file.name, S3_PATH.DocumentLog, {
        requestId,
      });
    }

    const payload = {
      id: newDataBlackboard.id,
      ...bodyUpdate,
      thumbnail,
      blackboardTemplateId: blackboardTemplate?.id,
      subItemId: subItem.id,
      requestId,
    };
    const result = await blackboardApi.updateBlackboard(payload);

    newDataBlackboard = { ...newDataBlackboard, ...result.data };
    tempBlackboardChangedRef.current = newDataBlackboard;
    prevDataBlackboardRef.current = newDataBlackboard;

    setDataBlackboardState(newDataBlackboard);
    dispatch(setDataBlackboard(newDataBlackboard));

    insertItemLog?.({
      field: "blackboard" as any,
      blackboardThumbnail: thumbnail,
      blackboardTemplateId: blackboardTemplate?.id,
      value: newDataBlackboard,
      requestId,
    });

    socket.updateBlackboard(documentItemSelected?.level!, result.data.id!, {
      ...payload,
      updatedAt: result.data.updatedAt,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blackboardTemplate?.id,
    isMobile,
    dataBlackboard,
    isDisabled,
    subItem?.id,
    dispatch,
    insertItemLog,
  ]);

  const handleChangeBlackboardData = useCallback(
    async (data: Blackboard) => {
      if (!dataBlackboard?.id || isDisabled || !subItem?.id) {
        return;
      }

      const body: Partial<Blackboard> = {
        ...data,
        updatedAt: new Date(),
      };
      const newDataBlackboard: Blackboard = {
        ...dataBlackboard,
        ...tempBlackboardChangedRef.current,
        ...body,
        subItemId: subItem.id,
      };
      tempBlackboardChangedRef.current = newDataBlackboard;
    },
    [dataBlackboard, isDisabled, subItem?.id]
  );

  const handleUpdateDataBlackboardState = useCallback(
    (data: Partial<Blackboard>) => {
      if (isDisabled || !subItem?.id) return;
      if (dataBlackboard) {
        const now = new Date();

        setDataBlackboardState((prev) => ({
          ...prev!,
          ...data,
          subItemId: subItem?.id,
          updatedAt: data.updatedAt ?? now,
        }));
      }
    },
    [dataBlackboard, subItem, isDisabled]
  );

  const onDeviceLinkage = async ({ result, type }: any) => {
    const { start_date, end_date, start_value, end_value } = result;

    if (type !== ReceivedDeviceType.PRESSURE) return;

    try {
      if (!cellComment || !dataBlackboard?.id) {
        return;
      }

      setIsSavingDataFromDevice.on();
      let commentManageExecute = dataBlackboard.commentManageExecute;
      if (!commentManageExecute?.length) {
        const cellOfBlackboards =
          cellComment?.cellLinkedData?.options?.blackboard?.cells || [];
        commentManageExecute = getCommentManageExecuteDefault({
          cellOfBlackboards: cellOfBlackboards as CellType[],
          familyInstance,
        });
      }

      const formatDate = "YYYY/MM/DD HH:mm:ss";
      commentManageExecute = commentManageExecute?.map(
        (comment: CommentManageExecute) => {
          if (comment.property !== CellProperty.TYPE_OF_EQUIPMENT) {
            return comment;
          }

          // override start time
          if (
            comment.typeOfEquipmentField === TypeOfEquipmentField.START_TIME &&
            start_date
          ) {
            const startDate = dayjs(start_date * 1000).format(formatDate);

            return {
              ...comment,
              value: start_value ? `${startDate} ${start_value}` : start_value,
            };
          }

          // override end time
          if (
            comment.typeOfEquipmentField === TypeOfEquipmentField.END_TIME &&
            end_date
          ) {
            const endDate = dayjs(end_date * 1000).format(formatDate);

            return {
              ...comment,
              value: end_value ? `${endDate} ${end_value}` : endDate,
            };
          }

          return comment;
        }
      );

      tempBlackboardChangedRef.current = {
        ...tempBlackboardChangedRef.current!,
        commentManageExecute,
      };

      handleUpdateDataBlackboardState({ commentManageExecute });
      await sleep(500);
      await handleSaveBlackboardData();
    } catch (err) {
      logDev(err);

      // revert commentManageExecute
      tempBlackboardChangedRef.current = {
        ...tempBlackboardChangedRef.current!,
        commentManageExecute: dataBlackboardRedux?.commentManageExecute,
      };
      handleUpdateDataBlackboardState({
        commentManageExecute: dataBlackboardRedux?.commentManageExecute,
      });
    } finally {
      setIsSavingDataFromDevice.off();
    }
  };

  return {
    isOpenSelectBlackboardPosition,
    dataBlackboard,
    isShowBlackboard,
    blackboardTemplate,
    loadingGetBlackboardData,
    blackboardPosition,
    isFocusBlackboard,
    isAllowDeviceLinkage,
    isSavingDataFromDevice,

    setIsFocusBlackboard,
    handleChangeItemData,
    handleOpenPositionBlackboardModal,
    handleClosePositionBlackboardModal,
    handleChangeBlackboardData,
    handleUpdateDataBlackboardState,
    handleSaveBlackboardData,
    onDeviceLinkage,
  };
};

export default useBlackboard;
